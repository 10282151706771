






import Vue from 'vue'
import Verification from '../../components/auth/Verification.vue'
export default Vue.extend({
    components: {
      Verification
    }
})
